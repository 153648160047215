<template>
  <div
    class="detailsBox specs"
    v-html="value"
  ></div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.specs {
  box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
  ::v-deep p {
    height: 40px;
    line-height: 40px;
    background: #f8f8f8;
    padding-left: 4px;
    color: #555;
    font-size: 24px;
  }

  ::v-deep table {
    border: 1px solid #eeeeee;
    width: 100% !important;

    tbody {
      tr {
        background: #f8f8f8;
        line-height: 35px;

        td {
          padding-left: 4px;
          color: #666;
        }
      }

      tr:nth-child(2n + 2) {
        background: #fff;
      }
    }
  }
}
</style>
