<template>
  <div class="details">
    <head-nav></head-nav>
    <product-img></product-img>
    <ProductBreadcrumb
      :router-list="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></ProductBreadcrumb>
    <div class="wrap">
      <div class="details-choice">
        <div
          class="choice-list"
          :class="{
            activate: index === indexpage
            }"
          v-for="(item, index) in list"
          :key="index"
          @click="onClickIndex(index)"
        >
          <img
            :src="item.img"
            alt=""
            v-if="index == indexpage"
          >
          <img
            :src="item.imgs"
            :alt="item.name"
            v-else-if="index !== indexpage"
          />
        </div>
      </div>
      <div class="detailsBoxs">
        <product-introduce
          v-if="indexpage == 0"
          :value="productInfo.Introduction"
        ></product-introduce>
        <product-specs
          v-else-if="indexpage == 1"
          :value='productInfo.specification'
        ></product-specs>
        <product-case
          v-else-if="indexpage == 2"
          :value='productInfo.successfulCases'
        ></product-case>
        <product-download
          v-else-if="indexpage == 3"
          :value='productInfo.productMaterials'
        ></product-download>

      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import imgs1 from '@/assets/image/product/details1.png';
import imgs2 from '@/assets/image/product/details2.png';
import imgs3 from '@/assets/image/product/details3.png';
import imgs4 from '@/assets/image/product/details4.png';
import imgs5 from '@/assets/image/product/details1-active.png';
import imgs6 from '@/assets/image/product/details2-active.png';
import imgs7 from '@/assets/image/product/details3-active.png';
import imgs8 from '@/assets/image/product/details4-active.png';
import returnImg from '@/assets/image/product/fanhui.png';

import HeadNav from './head-nav';
import ProductBreadcrumb from './product-breadcrumb';
import ProductImg from './product-img';
// 四个选项
import ProductCase from './product-case';
import ProductDownload from './product-download';
import ProductIntroduce from './product-introduce';
import ProductSpecs from './product-specs';
// 四个选项
import Footers from './footers';

export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  components: {
    HeadNav,
    ProductBreadcrumb,
    ProductImg,
    ProductCase,
    ProductDownload,
    ProductIntroduce,
    ProductSpecs,
    Footers,
  },
  apollo: {
    productInfo: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query($id: ID!) {
              productInfo: Product(where: { id: $id }) {
                id
                name
                Introduction
                specification
                productMaterials {
                  id
                  material {
                    originalFilename
                    publicUrl
                  }
                  createdAt
                }
                successfulCases {
                  id
                  image {
                    publicUrl
                  }
                  name
                }
                en_Product {
                  id
                }
              }
            }
          `;
        }
        return gql`
          query($id: ID!) {
            productInfo: EnProduct(where: { id: $id }) {
              id
              name
              Introduction
              specification
              productMaterials {
                id
                material {
                  originalFilename
                  publicUrl
                }
                createdAt
              }
              successfulCases {
                id
                image {
                  publicUrl
                }
                name
              }
              product {
                id
              }
            }
          }
        `;
      },
      variables() {
        return {
          id: this.productId,
        };
      },
    },
  },
  data() {
    return {
      breadcrumb: [
        {
          path: '/',
          label: 'product-sort.home',
        },
        {
          path: '/product-sort',
          label: 'product-sort.classification',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/product-sort',
          label: 'toback',
          image: returnImg,
        },
      ],
      indexpage: 0,
      productInfo: {
        Introduction: '',
        specification: '',
        productMaterials: '',
        successfulCases: '',
      },
      list: [
        {
          id: 1,
          name: '这是一张图片',
          imgs: imgs1,
          img: imgs5,
        },
        {
          id: 2,
          name: '这是一张图片',
          imgs: imgs2,
          img: imgs6,
        },
        {
          id: 3,
          name: '这是一张图片',
          imgs: imgs3,
          img: imgs7,
        },
        {
          id: 4,
          name: '这是一张图片',
          imgs: imgs4,
          img: imgs8,
        },
      ],
    };
  },
  watch: {
    productInfo(value) {
      if (this.breadcrumb.length === 3) {
        this.breadcrumb[2].label = value.name;
      } else {
        this.breadcrumb.push({
          label: value.name,
        });
      }
    },
    '$i18n.locale': {
      handler(newValue, oldValue) {
        if (oldValue === 'cn') {
          this.$router.replace({
            params: {
              productId: this.productInfo.en_Product.id,
            },
          });
        } else if (oldValue === 'en') {
          this.$router.replace({
            params: {
              productId: this.productInfo.product.id,
            },
          });
        }
      },
    },
  },
  methods: {
    onClickIndex(index) {
      this.indexpage = index;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .productBox .wrap .productNav {
  top: -530px !important;
}

.details {
  .details-choice {
    margin-top: -450px;
    display: flex;
    flex: 1;
    padding: 25px 0;
    background: #f2f2f2;
    border-bottom: 1px solid #ffbf00;
    position: relative;

      &:hover{
        cursor: pointer;
      }

    .choice-list {
      position: relative;

      &.activate {
        &::before {
          content: '';
          width: 0;
          height: 0;
          border-width: 12px 14px 0;
          border-style: solid;
          border-color: #f2f2f2 transparent transparent;
          position: absolute;
          top: 174px;
          left: 45%;
          z-index: 2;
        }

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-width: 12px 14px 0;
          border-style: solid;
          border-color: #ffbf00 transparent transparent;
          position: absolute;
          top: 175px;
          left: 45%;
          z-index: 1;
        }
      }

      width: 299px;
      border-right: 1px solid #ddd;
      text-align: center;
      height: 150px;

      img {
        height: 120px;
      }
    }

    .choice-list:last-child {
      width: 300px;
      border: none;
    }
  }

  .detailsBoxs {
    margin-bottom: 120px;
    .detailsBox {
      box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
