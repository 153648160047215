<template>
  <div>
    <div class="details-box introduce">
      <p
        class="details-content"
        v-html="value"
      >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.introduce {
  box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
  padding: 70px 90px 140px;
  background: #fff;

  .details-content {
    line-height: 24px;
  }
}
</style>
