<template>
  <div class="details-box download wrap">
    <div
      class="details-box-list clearfix"
      v-for="(item, index) in value"
      :key="index"
    >
      <div class="list-left">
        <img
          :src="item.material.publicUrl"
          alt=""
        />
        <span>{{item.material.originalFilename}}</span>
      </div>
      <div class="list-right">
        <span>{{item.createdAt | formatDate}}</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 60px 0 70px 0;

  .details-box-list {
    margin: 0 100px;
    border-top: 1px solid #eeeeee;
    height: 100px;
    line-height: 100px;

    &:hover{
      cursor: pointer;
    }

    .list-left {
      float: left;
      position: relative;

      img {
        position: relative;
        left: 0;
        top: -30px;
        width: 40px;
        height: 40px;
        margin-right: 30px;
      }
    }

    .list-right {
      float: right;
    }
  }
}
</style>
