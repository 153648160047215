<template>
  <div class="wrap">
    <div class="detailsBox case">
      <div
        class="detailsBox-list"
        v-for="(item, index) in value"
        :key="index"
        @click.stop="handleClone(item.id)"
      >
        <div
          v-if="allMask === item.id"
          class="listEnlargeImg"
        >
          <i
            class="iconfont icon-cha"
            @click.stop="handleCloneMask()"
          ></i>
          <img
            :src="item.image.publicUrl"
            alt=""
          >

        </div>

        <img
          class="listImg"
          :src="item.image.publicUrl+'!product_case_sanpshot'"
          alt=""
        />
        <div class="listMask">
          <p>{{ item.name }}</p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      allMask: '',
    };
  },
  methods: {
    handleClone(index) {
      this.allMask = index;
      console.log(this.allMask);
    },
    handleCloneMask() {
      this.allMask = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.case {
  overflow: hidden;
  box-shadow: 10px 3px 30px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  background: #fff;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-self: start;

  .detailsBox-list {
    &:hover {
      cursor: pointer;
      .listMask {
        top: 0;
        transition: all 500ms;
        -webkit-transition: 5s;
        -webkit-transition: max-height 5s ease-in-out;
        -moz-transition: max-height 5s ease-in-out;
        -o-transition: max-height 5s ease-in-out;
        transition: max-height 5s ease-in-out;

        p {
          text-align: center;
          color: #ffbf00;
          margin-top: 80px;
        }
      }
    }
    width: 300px;
    height: 180px;
    margin: 30px 40px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    .listImg {
      width: 100%;
      height: 180px;
    }

    .listMask {
      position: absolute;
      top: 140px;
      left: 0;
      height: 180px;
      width: 100%;
      background: #000;
      opacity: 0.7;

      p {
        text-align: center;
        color: #fff;
        margin-top: 14px;
      }
    }

    .listEnlargeImg {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 10;
      text-align: center;
      img {
        position: absolute;
        top: 52%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -khtml-transform: translate(-50%, -50%);
        z-index: 20;
        width: 60%;
        height: auto;
        border: 10px solid #fff;
      }
      .icon-cha {
        position: absolute;
        top: 10%;
        right: 10%;
        font-size: 30px;
        color: #fdfdfd;
      }
    }
  }
}
</style>
